












































































import { DashmixBreadcrumbsProps, DashmixIconName, DashmixSelectItem } from '@movecloser/ui-core'
import { Component, Vue } from 'vue-property-decorator'
import { ContainersRegistry, ContentStructure, ModulesRegistry } from '@movecloser/page-builder'

import {
  AllowedThemeSchema,
  Identifier,
  Inject,
  IRelatedService,
  logger,
  RelatedServiceType
} from '../../../backoffice'

import { FormInput } from '../../shared/components/form'
import { FormSelect } from '../../shared/components/form/Select/Select.vue'
import { EditModeLayout, Loader, ModelListHeader, Property } from '../../shared/components'

import { initBreadcrumbs } from '../../root/helpers/breadcrumbs'

import { composeLayoutSlotData } from '../helpers/layouts'
import { Layout } from '../models/layout'
import { LayoutSlotForm } from '../components/LayoutSlotForm.vue'
import {
  ILayout,
  ILayoutRepository,
  LayoutData,
  LayoutModel,
  LayoutRepositoryType,
  LayoutSlot,
  LayoutSlotData
} from '../contracts'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Component<EditLayout>({
  name: 'EditLayout',
  components: {
    FormSelect,
    EditModeLayout,
    FormInput,
    LayoutSlotForm,
    Loader,
    ModelListHeader,
    Property
  },
  mounted () {
    this.loadLayout()
  }
})
export class EditLayout extends Vue {
  @Inject(LayoutRepositoryType)
  private layoutRepository!: ILayoutRepository

  @Inject(RelatedServiceType)

  public readonly relatedService!: IRelatedService

  public containers: ContainersRegistry = {}
  public form = 'editLayout'
  private id: Identifier = Number(this.$route.params.id)
  private isActive: boolean = true
  private isEdit: boolean = !!this.id
  public isLoading: boolean = false
  public isSaving: boolean = false
  private layout: LayoutModel | null = null
  public modules: ModulesRegistry = {}
  public name: string = ''
  public navName: string = ''
  private slots: LayoutSlotData | null = null
  public theme: string = ''

  public Icons = DashmixIconName
  public LayoutSlot = LayoutSlot

  public get breadcrumbs (): DashmixBreadcrumbsProps {
    return {
      items: [
        {
          label: `${this.$t('settings.layouts.title')}`,
          target: { name: 'layouts.layouts.list' }
        },
        {
          label: this.pageTitle,
          target: { name: 'layouts.layouts.edit' }
        }
      ],
      root: initBreadcrumbs.root,
      showOnMobile: false
    }
  }

  public get pageTitle (): string {
    return this.isEdit
      ? `${this.$t('settings.layouts.editTitle', { name: this.navName })}`
      : `${this.$t('settings.layouts.createTitle')}`
  }

  public get themeOptions (): DashmixSelectItem[] {
    return Object.values(AllowedThemeSchema).map(theme => ({
      label: this.$t(`office.themeSchemas.${theme}`).toString(),
      value: theme
    }))
  }

  public extractLayoutStructure (slot: LayoutSlot): ContentStructure {
    if (!this.slots) {
      return {}
    }

    const found = this.slots[slot]
    return typeof found === 'object' && !Array.isArray(found) ? found : {}
  }

  public async submit (): Promise<void> {
    const payload: Omit<LayoutData, 'id'> = {
      containers: this.containers,
      isActive: this.isActive,
      modules: this.modules,
      name: this.name,
      slots: composeLayoutSlotData(this.slots || {}),
      theme: this.theme
    }

    this.isSaving = true

    try {
      const data = this.isEdit
        ? await this.layoutRepository.update(this.id, payload)
        : await this.layoutRepository.create(payload)

      if (!this.isEdit && data.layoutId) {
        this.id = data.layoutId

        await this.$router.push({
          name: 'layouts.layouts.edit',
          params: { id: `${data.layoutId}` }
        })
      }

      await this.updateLayout(payload)
    } catch (e) {
      logger(e, 'error')
    }

    this.isSaving = false
  }

  public update (data: LayoutSlotData) {
    this.slots = { ...this.slots, ...data }
  }

  protected async loadLayout (): Promise<void> {
    this.slots = composeLayoutSlotData({})
    if (!this.isEdit) {
      return
    }

    this.isLoading = true

    try {
      const data = await this.layoutRepository.get(this.id)
      await this.updateLayout(data, this.id)
    } catch (e) {
      logger(e, 'error')
    }

    this.isLoading = false
  }

  protected async updateLayout (payload: Omit<LayoutData, 'id'>, id?: Identifier) {
    payload = { ...payload }
    if (typeof payload.theme !== 'string' || payload.theme.length === 0) {
      payload.theme = AllowedThemeSchema.Default
    }

    this.layout = Layout.hydrate<LayoutData, ILayout>({
      ...this.layout,
      ...payload,
      id: id ?? this.id
    })
    this.isEdit = !!this.id

    this.containers = this.layout.containers
    this.isActive = this.layout.isActive
    this.modules = this.layout.modules
    this.name = this.layout.name
    this.navName = this.layout.name
    this.slots = this.layout.slots
    this.theme = this.layout.theme
  }
}

export default EditLayout
