






























































import { Builder, Viewer } from '@movecloser/page-builder'
import { defineComponent, SetupContext } from '@vue/composition-api'

import { FormSelect } from '../form'

import { ContainerDrawer, FormDrawer, ModeSwitcher, Module } from './partials'
import { PageBuilderProps } from './PageBuilder.contracts'
import { pageBuilderProps, usePageBuilder } from './PageBuilder.hooks'

import { DashmixIconName } from '@movecloser/ui-core'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const PageBuilder = defineComponent({
  name: 'PageBuilder',
  components: {
    Builder,
    ContainerDrawer,
    FormDrawer,
    FormSelect,
    ModeSwitcher,
    Viewer
  },
  props: pageBuilderProps,

  setup (props: PageBuilderProps, ctx: SetupContext) {
    return {
      ...usePageBuilder(props, ctx),
      module: Module,
      icons: DashmixIconName
    }
  }
})

export default PageBuilder
