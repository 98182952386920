// Copyright © 2022 Move Closer

import { VueConstructor } from 'vue'

import { PageBuilder } from '../../shared/components'

import { AddonRegistry, ContentTypeConfig, defaultEditor, defaultListing } from '../services/content-type-manager'
import DelayedAddon from '../components/addons/DelayedAddon.vue'
import LayoutAddon from '../components/addons/LayoutAddon.vue'
import ListCollection from '../components/ListCollection.vue'
import ListWithNodes from '../components/ListWithNodes.vue'
import MetaAddon from '../components/addons/MetaAddon.vue'
import DynamicPageAddon from '../components/addons/DynamicPageAddon.vue'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export enum Addon {
  Delayed = 'delayed',
  Meta = 'meta',
  Layout = 'layout',
  DynamicPage = 'product-config'
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const contentAddons: AddonRegistry = {
  [Addon.Delayed]: { component: DelayedAddon, label: 'content.addonsLabels.delayed', priority: 1 },
  [Addon.Layout]: { component: LayoutAddon, label: 'content.addonsLabels.layout', priority: 2 },
  [Addon.Meta]: { component: MetaAddon, label: 'content.addonsLabels.meta', priority: 2 },
  [Addon.DynamicPage]: { component: DynamicPageAddon, label: 'content.addonsLabels.dynamicPage', priority: 2 }
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const contentTypes: Record<string, ContentTypeConfig> = {
  //
  // Note! Page is basic content type, so it must be registered as a first one.
  //
  page: {
    actions: {},
    editor: defaultEditor,
    filters: null,
    label: 'content.contentTypes.page',
    listing: defaultListing,
    loadAs: null,
    loadWith: [],
    hasParent: true,
    hasPreview: true,
    parentType: null,
    properties: []
  }
  // Other types goes down here...
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const editorRegistry: Record<string, VueConstructor> = {
  [defaultEditor]: PageBuilder
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export enum List {
  Collection = 'collection',
  TreeNode = 'treeNodes'
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const listingsRegistry: Record<string, VueConstructor> = {
  [List.Collection]: ListCollection,
  [List.TreeNode]: ListWithNodes
}
