












































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { AnyObject, IModal, ModalType } from '@movecloser/front-core'
import { DashmixIconName, DashmixSelectItem, DashmixTheme, SizeMap } from '@movecloser/ui-core'

import {
  Inject,
  IRelatedService,
  PickerSelectionCallback,
  Related,
  RelatedServiceType
} from '../../../backoffice'

import { FormCheckbox, FormInput, FormSelect } from '../../shared/components/form'
import { Loader } from '../../shared/components'
import SnippetForm from '../../../modules/partials/SnippetForm/SnippetForm.vue'

import { mediaTypes } from '../../media/contracts'
import { ProductGalleryItem } from './ProductGalleryItem.vue'
import { ProductVariantData } from '../contracts'

/**
 * @author Łukasz Jakubowski <lukasz.jakubowski@movecloser.pl>
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<VariantForm>({
  name: 'VariantForm',
  components: {
    Loader,
    FormCheckbox,
    FormInput,
    FormSelect,
    ProductGalleryItem,
    SnippetForm
  },
  created () {
    this.payload = { ...this.variant }
    this.snippet = this.variant.snippet ?? null
  }
})
export class VariantForm extends Vue {
  @Inject(RelatedServiceType)
  public readonly relatedService!: IRelatedService

  @Prop({ type: Object, required: true })
  public variant!: ProductVariantData

  @Prop({ type: String, required: true })
  public form!: string

  @Prop({ type: Boolean, required: false, default: true })
  public editable!: boolean

  @Prop({ type: Function, required: false })
  public onVariantChange!: (payload: ProductVariantData) => void

  @Inject(ModalType)
  private modalConnector!: IModal

  public buttonTheme = DashmixTheme
  public icons = DashmixIconName
  public payload: ProductVariantData = {} as ProductVariantData
  public snippet: Related | null = null

  public get activenessOptions (): DashmixSelectItem[] {
    return [
      {
        label: String(this.$t('products.variants.edit.isVisible.true')),
        value: true
      }, {
        label: String(this.$t('products.variants.edit.isVisible.false')),
        value: false
      }
    ]
  }

  public get identifier (): string {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return this.payload.identifier?.value ?? (this.payload.identifiers && this.payload.identifiers[0] ? this.payload.identifiers[0].value : null) // todo: temp, handle legacy data format
  }

  public set identifier (value: string) {
    this.payload.identifier = {
      type: 'color',
      value
    }

    this.onVariantChange(this.payload)
  }

  public get isLegacy (): boolean {
    return 'identifier' in this.payload
  }

  public openFilePicker () {
    this.modalConnector.open('file', {
      multiple: true,
      onSelection: this.addItems,
      onClose: () => this.modalConnector.close(),
      config: {
        allowedMediaType: mediaTypes.Image
      }
    }, { size: SizeMap.XLarge })
  }

  public pickRelated (
    picker: string,
    onSelection: PickerSelectionCallback,
    selected?: Related,
    options?: AnyObject
  ) {
    this.modalConnector.open(picker, {
      config: options,
      onClose: () => this.modalConnector.close(),
      onSelection,
      selected
    }, { size: SizeMap.Large })
  }

  protected async addItems (data: Related[]) {
    this.payload.images.push(...data)
    this.payload = { ...this.payload }
  }

  protected async deleteItem (index: number) {
    const images = this.payload.images.filter((el: Related, i: number) => i !== index)
    this.payload = { ...this.payload, images }
  }

  @Watch('payload')
  public onPayloadChange () {
    this.onVariantChange(this.payload)
  }

  @Watch('snippet')
  private onSnippetChange (snippet: Related) {
    this.payload = {
      ...this.payload,
      snippet
    }
  }
}

export default VariantForm
