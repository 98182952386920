
































import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'
import { AnyObject, ICollection, IModal, ModalType } from '@movecloser/front-core'
import { SlickItem, SlickList } from 'vue-slicksort'
import { VueConstructor } from 'vue'

import { Identifier, Inject } from '../../../backoffice'

import { defaultScrollConfig } from '../../shared/config/scroll'
import { ScrollHandle } from '../../shared/directives/scroll'
import { ScrollHandleConfig } from '../../shared/contracts/components'

import { itemComponentConfig } from '../maps/sets'
import { ISetItemsRepository, SetItemModel, SetItemsRepositoryType, SetModel } from '../contracts'
import { SetDraggableItem } from './SetDragabbleItem.vue'

@Component({
  name: 'SetDraggableList',
  components: { SetDraggableItem, SlickList, SlickItem },
  directives: { ScrollHandle }
})
export class SetDraggableList extends Vue {
  @Prop({ type: Boolean, required: false, default: false })
  public incrementalLoading!: boolean

  @Prop({ type: Boolean, required: false, default: false })
  public isDisabled?: boolean

  @Prop({ type: Object, required: false, default: () => defaultScrollConfig })
  public scrollConfig!: ScrollHandleConfig

  @Prop({ type: Object, required: true })
  public set!: SetModel

  @PropSync('items', { type: Array, required: true, default: () => [] })
  public setItems!: ICollection<SetItemModel>

  @Prop({ type: Number, required: false })
  public siteId?: number

  @Inject(ModalType)
  protected modalConnector!: IModal

  @Inject(SetItemsRepositoryType)
  protected setItemRepository!: ISetItemsRepository

  public hoveredItemId: number | null = null

  public get itemComponent (): VueConstructor {
    if (typeof itemComponentConfig[this.set.type] === 'undefined') {
      throw new Error(`No row component register for [${this.set.type}]`)
    }
    return itemComponentConfig[this.set.type]
  }

  public onHover (itemId: Identifier) {
    this.hoveredItemId = typeof itemId === 'string' ? parseInt(itemId) : itemId
  }

  public handleScroll () {
    this.$emit('scroll-handle')
  }

  public isItemDisabled (index: number): boolean {
    const item = this.setItems[index] as AnyObject
    const isVisible = item.data?.isVisible ?? true

    return !isVisible
  }

  protected remove (itemId: Identifier) {
    this.setItemRepository.remove(this.set.id, itemId).then(() => {
      this.$emit('on-remove', itemId)
    }).catch(error => {
      console.log(error)
    })
  }

  public lock (itemId: Identifier) {
    this.$emit('lockItem', itemId)
  }

  public move (args: { newIndex: number; oldIndex: number }) {
    this.$emit('moveItem', args)
  }

  public unlock (itemId: Identifier) {
    this.$emit('unlockItem', itemId)
  }
}

export default SetDraggableList
