


















import { Component, Prop } from 'vue-property-decorator'

import { AbstractSetItem } from './AbstractSetItem'
import { AnyObject } from '@movecloser/front-core'
import { Inject, IRelatedService, isRelated, RelatedServiceType } from '../../../../backoffice'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component<ProductsSetItem>({
  name: 'ProductsSetItem',
  mounted () {
    if (!this.data.category) {
      return
    }

    if (!isRelated(this.data.category) && 'id' in this.data.category) {
      this.category = this.data.category
      return
    }

    this.getCategoryFromRelated()
  }
})
export class ProductsSetItem extends AbstractSetItem<any> {
  @Inject(RelatedServiceType)
  protected readonly relatedService!: IRelatedService

  @Prop({ type: Number, required: false })
  public siteId?: number

  public category: null | AnyObject = null

  public get displayName (): string {
    return `${this.data.name} (${this.data.sku})`
  }

  public get count (): number {
    if (!this.data.variants) {
      return 0
    }

    if (Array.isArray(this.data.variants)) {
      return this.data.variants.length
    }

    return Object.keys(this.data.variants).length
  }

  public async getCategoryFromRelated (): Promise<void> {
    try {
      this.category = await this.relatedService.describe(this.data.category, this.siteId)
    } catch (e) {
      console.warn(e)
    }
  }
}

export default ProductsSetItem
