// Copyright © 2022 Move Closer
import { Injectable, IResponse, Payload, Repository, ResourceActionFailed } from '@movecloser/front-core'
import { Identifier, RelatedLoader, RelatedQuery, RelatedRecord, resolveFromStatus } from '../../../backoffice'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
@Injectable()
export class RelatedRepository extends Repository<RelatedRecord> implements RelatedLoader {
  public async load (query: RelatedQuery, siteId?: Identifier): Promise<RelatedRecord> {
    const response: IResponse = await this.connector.call(
      'related',
      'list',
      {},
      { ...this.composeQuery(query) },
      siteId ? { 'X-Site': siteId.toString() } : undefined
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }

    return response.data.data as RelatedRecord
  }

  /**
   * Compose correct format of query params.
   */
  protected composeQuery (query: RelatedQuery): Payload {
    const composed: Payload = {}

    for (const [key, values] of Object.entries(query)) {
      composed[key] = typeof values === 'undefined'
        ? ''
        : values.filter((value, index, self) => (
          self.indexOf(value) === index && !!value
        )).join(',')
    }

    return composed
  }
}
