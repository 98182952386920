

















import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { DashmixSelectItem } from '@movecloser/ui-core'

import { Identifier, Inject, logger } from '../../../../backoffice'

import { Addon } from '../../config/content-types'
import { OnVariantChangeCallback, VariantModel } from '../../contracts'
import { mockedDirtyCallback, mockedVariantChangeCallback } from '../../helpers/components'
import { ISiteResolver, SiteResolverType } from '../../../root/services/site-resolver'

type DynamicPageAddonData = {
  siteId: Identifier | null
  slugProperty: string
}

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Component<DynamicPageAddon>({
  name: 'DynamicPageAddon',
  mounted () {
    this.getVariantProperty()
    this.getSitesDictionary()
  }
})
export class DynamicPageAddon extends Vue {
  @Prop({ type: Boolean, required: false, default: true })
  public disabled!: boolean

  @Prop({ type: Function, required: false, default: mockedDirtyCallback })
  protected onChange!: OnVariantChangeCallback

  @Prop({ type: Object, required: false, default: mockedVariantChangeCallback })
  protected variant!: VariantModel

  @Inject(SiteResolverType)
  private siteResolver!: ISiteResolver

  public sitesOptions: DashmixSelectItem[] | [] = []
  public loading: boolean = false
  public site: Identifier | null = null
  public slugProperty: string = ''

  protected async getSitesDictionary (): Promise<void> {
    this.loading = true

    try {
      const dict = await this.siteResolver.getDictionary()

      this.sitesOptions = [...dict].map(site => {
        return {
          value: site.id,
          label: `${site.domain} ${site.locale}`
        }
      })

      this.sitesOptions.unshift({
        value: null,
        label: this.$t('content.addons.dynamicPage.siteNotSelected').toString()
      })
    } catch (e) {
      logger(e, 'warn')
    }

    this.loading = false
  }

  protected getVariantProperty (): void {
    const configFromModel = this.variant.getProperty<DynamicPageAddonData>(Addon.DynamicPage)

    this.site = configFromModel?.siteId || null
    this.slugProperty = configFromModel?.slugProperty || ''
  }

  @Watch('site', { deep: true })
  protected onSiteSelected (site: Identifier | null): void {
    this.variant.setProperty<DynamicPageAddonData>(Addon.DynamicPage, {
      slugProperty: this.slugProperty,
      siteId: site
    })

    this.onChange(this.variant)
  }

  @Watch('slugProperty')
  protected onSlugChanged (slugProperty: string): void {
    this.variant.setProperty<DynamicPageAddonData>(Addon.DynamicPage, {
      slugProperty,
      siteId: this.site
    })

    this.onChange(this.variant)
  }

  @Watch('variant', { deep: true })
  protected onVariantChange (newVariant: VariantModel, oldVariant: VariantModel) {
    if (JSON.stringify(newVariant.toObject()) !== JSON.stringify(oldVariant.toObject())) {
      this.getVariantProperty()
    }
  }
}

export default DynamicPageAddon
