







































import { VueConstructor } from 'vue'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Hint } from '@movecloser/ui-core'
import { ICollection } from '@movecloser/front-core'

import { Identifier, Inject, IRelatedService, RelatedServiceType } from '../../../backoffice'

import {
  AddSetItemModalPayload,
  ISetItemsRepository,
  SetItemModel,
  SetItemsRepositoryType
} from '../contracts'

@Component<AddSetItemModal>({
  name: 'AddSetItemModal',
  components: {},

  mounted () {
    this.loadContents()
  }
})
class AddSetItemModal extends Vue {
  @Prop({ type: Object, required: true })
  public payload!: AddSetItemModalPayload

  @Inject(SetItemsRepositoryType)
  private setItemsRepository!: ISetItemsRepository

  @Inject(RelatedServiceType)
  protected relatedService!: IRelatedService

  private setItemCollection!: ICollection<SetItemModel>
  public isLoading: boolean = false
  public itemOptions: Hint[] = []
  public selectedOption: Hint[] = []
  public searchParam: string = ''

  public get component (): VueConstructor | null {
    if (!this.payload.component || typeof this.payload.component !== 'function') {
      return null
    }
    return this.payload.component
  }

  public onClear () {
    this.searchParam = ''
  }

  public onDelete () {
    this.selectedOption = []
  }

  public onSearch (value: string) {
    this.searchParam = value
  }

  public onSelect (selectedHint: Hint) {
    this.selectedOption = [selectedHint]
  }

  public loadContents (searchParams?: string) {
    this.isLoading = true
    this.setItemsRepository.loadAvailableItems(this.payload.setType, { q: searchParams }, this.payload.siteId)
      .then((contents: ICollection<SetItemModel>) => {
        this.setItemCollection = contents
        this.itemOptions = AddSetItemModal.contentsToHints(contents)
      })
      .finally(() => {
        this.isLoading = false
      })
  }

  /**
   * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
   * @protected
   */
  protected changeSite (): void {
    this.itemOptions = []

    this.$nextTick(() => {
      this.loadContents()
    })
  }

  protected close () {
    this.payload.onClose()
  }

  @Watch('searchParam')
  protected onSearchParamsChange (searchParams: string): void {
    this.loadContents(searchParams)
  }

  protected submit () {
    if (!this.selectedOption[0]) {
      return
    }

    this.payload.onConfirm(this.selectedOption[0].value as Identifier)
  }

  public static contentsToHints (contents: ICollection<SetItemModel>): Hint[] {
    return [...contents].map(
      (content: SetItemModel) => {
        return {
          value: content.id,
          label: content.displayName()
        }
      }
    )
  }
}

export default AddSetItemModal
