// Copyright © 2022 Move Closer

import { Methods, ResourcesRegistry } from '@movecloser/front-core'

// TODO: Change csrf to `true` when API is deployed.
export const resources: ResourcesRegistry = {
  products: {
    prefix: 'api/management',
    methods: {
      list: {
        url: 'products',
        method: Methods.Get,
        auth: true
      },
      update: {
        url: 'products/{id}',
        method: Methods.Put,
        params: ['id'],
        auth: true,
        shorthand: 'editProduct',
        meta: { csrf: true, toast: true }
      },
      item: {
        url: 'products/{id}',
        method: Methods.Get,
        params: ['id'],
        auth: true,
        shorthand: 'getProduct',
        meta: { csrf: true, toast: true }
      },
      delete: {
        url: 'products/{id}',
        method: Methods.Delete,
        params: ['id'],
        auth: true,
        shorthand: 'editProduct',
        meta: { csrf: true, toast: true }
      }
    }
  },
  productFilters: {
    prefix: 'api/management',
    methods: {
      list: {
        url: 'settings/productFilters',
        method: Methods.Get,
        auth: true
      }
    }
  }
}
