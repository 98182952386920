
/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export enum FloatingOrderRenewalPosition {
  Left = 'left',
  Right = 'right'
}

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const DEFAULT_OFFSET_TOP = '150'
