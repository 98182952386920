










































import { DashmixIconName } from '@movecloser/ui-core'
import { defineComponent, SetupContext } from '@vue/composition-api'

import { ModuleSettings } from '../../../../../backoffice'

import { formDrawerProps, useFormDrawer } from './FormDrawer.hooks'
import { FormDrawerProps } from '../PageBuilder.contracts'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const FormDrawer = defineComponent({
  name: 'FormDrawer',
  components: { ModuleSettings },
  props: formDrawerProps,
  setup: (props: FormDrawerProps, ctx: SetupContext) => ({
    ...useFormDrawer(props, ctx),
    iconsSet: DashmixIconName
  })
})

export default FormDrawer
