// Copyright © 2021 Move Closer
import { DashmixIconName, TableHead, TableSearchBarAction } from '@movecloser/ui-core'

import { UserModel } from '../../auth/contracts/models'

export const productsTableHead: TableHead = [
  {
    column: 'variant_list_toggle',
    label: '',
    sortable: false,
    isSorted: false,
    width: '.1fr'
  },
  {
    column: 'sku',
    label: 'SKU',
    sortable: true,
    isSorted: false,
    width: '.75fr'
  },
  {
    column: 'name',
    label: 'Nazwa',
    sortable: true,
    isSorted: false,
    width: '2fr'
  },
  {
    column: 'category',
    label: 'Kategoria',
    sortable: false,
    isSorted: false,
    width: '1fr'
  },
  {
    column: 'hasVariants_isActive',
    label: 'Widoczność',
    sortable: false,
    isSorted: false,
    width: '.6fr'
  },
  {
    column: 'updatedAt',
    label: 'Modyfikowany',
    sortable: true,
    isSorted: true,
    width: '1fr'
  }
]

export enum ProductsListsActions {
  Delete = 'delete',
  Generate = 'generate',
  Preview = 'preview'
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const productsRowActionsFactory = (domain: string, user: UserModel | null): TableSearchBarAction[] => {
  const actions = []
  // TODO - add permissions when API ready
  actions.push(
    {
      label: 'Edytuj',
      icon: DashmixIconName.EyeSolid,
      isBulkAction: true,
      action: ProductsListsActions.Preview
    },
    {
      label: 'Usuń',
      icon: DashmixIconName.TrashSolid,
      isBulkAction: true,
      action: ProductsListsActions.Delete
    }
  )
  return actions
}
