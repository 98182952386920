

























import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { DashmixIconName, Hint } from '@movecloser/ui-core'
import { ICollection, IModal, ModalType } from '@movecloser/front-core'

import {
  IRelatedService,
  Inject,
  PossibleRelatedType,
  Related,
  RelatedServiceType
} from '../../../../backoffice'
import { Loader } from '../../../shared/components/Loader'

import {
  IProductsRepository,
  ProductModel,
  ProductsRepositoryType
} from '../../contracts'
import { PickerPayload } from '../../../content/contracts'

@Component<ProductPickerModal>({
  name: 'ProductPickerModal',
  components: { Loader },

  mounted () {
    this.loadContents()
  }
})
export class ProductPickerModal extends Vue {
  @Prop({ type: Object, required: true })
  public payload!: PickerPayload<ProductModel>

  @Inject(ModalType)
  protected modalConnector!: IModal

  @Inject(ProductsRepositoryType)
  private productsRepository!: IProductsRepository

  @Inject(RelatedServiceType)
  protected relatedService!: IRelatedService

  protected items!: ICollection<ProductModel>
  public isLoading: boolean = false
  public itemOptions: Hint[] = []
  public searchParam: string = ''
  public selectedOption: Hint[] = []
  public icons = DashmixIconName

  public get isReady () {
    return this.selectedOption.length > 0
  }

  public apply (): void {
    if (!this.selectedOption[0]) {
      return
    }

    if (this.payload.onSelection && typeof this.payload.onSelection === 'function') {
      const result = this.selectedOption.map(item => (
        { type: PossibleRelatedType.Product, value: item.value } as Related
      ))

      this.payload.onSelection(result[0])
    }

    this.modalConnector.close()
  }

  public close (): void {
    if (this.payload.onClose && typeof this.payload.onClose === 'function') {
      this.payload.onClose()
    }
    this.modalConnector.close()
  }

  public loadContents (searchParams?: string) {
    this.isLoading = true
    this.productsRepository.loadCollection(searchParams ? { q: searchParams } : {}, this.payload.config?.siteId)
      .then((contents: ICollection<ProductModel>) => {
        this.items = contents
        this.itemOptions = ProductPickerModal.contentsToHints(contents)
      })
      .finally(() => {
        this.isLoading = false
      })
  }

  public onClear () {
    this.searchParam = ''
  }

  public onDelete () {
    this.selectedOption = []
  }

  public onSearch (value: string) {
    this.searchParam = value
  }

  public onSelect (selectedHint: Hint) {
    this.selectedOption = [selectedHint]
  }

  @Watch('searchParam')
  protected onSearchParamsChange (searchParams: string): void {
    this.loadContents(searchParams)
  }

  public static contentsToHints (contents: ICollection<ProductModel>): Hint[] {
    return [...contents].map(
      (content: ProductModel) => {
        return {
          value: content.id,
          label: content.displayName()
        }
      }
    )
  }
}

export default ProductPickerModal
