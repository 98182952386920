// Copyright © 2021 Move Closer
import { DashmixIconName, TableHead, TableSearchBarAction, DashmixTheme } from '@movecloser/ui-core'
import { VueConstructor } from 'vue'

import { PossibleRelatedType, SetType } from '../../../backoffice'

import { UserModel } from '../../auth/contracts/models'

import {
  canDeleteArticleSet, canDeleteProductSet,
  canEditArticleSet, canEditProductSet
} from '../config/guards'
import { ModelsConfig } from '../contracts/data'

import { articlesItemAdapterMap } from '../models/articles-item.adapter'
import ArticlesSetItem from '../components/SetItem/ArticlesSetItem.vue'
import { ArticlesItem } from '../models/articles-item'
import { ProductsItem } from '../models/products-item'
import ProductsSetItem from '../components/SetItem/ProductsSetItem.vue'
import { productsItemAdapterMap } from '../models/products-item.adapter'
import SetTableRow from '../components/SetTableRow.vue'
import SourcesForm from '../components/SourcesForm.vue'

export const setsTableHead: TableHead = [
  {
    column: 'name',
    label: 'Nazwa',
    sortable: true,
    isSorted: false,
    width: '2fr'
  },
  {
    column: 'author',
    label: 'Autor',
    sortable: true,
    isSorted: false,
    width: '1fr'
  },
  {
    column: 'setsNumber',
    label: 'Ilość elementów',
    sortable: true,
    isSorted: false,
    width: '0.75fr'
  },
  {
    column: 'strategy',
    label: 'Strategia',
    sortable: true,
    isSorted: false,
    width: '1fr'
  },
  {
    column: 'createdDate',
    label: 'Data utworzenia',
    sortable: true,
    isSorted: false,
    width: '0.75fr'
  },
  {
    column: 'updatedAt',
    label: 'Data modyfikacji',
    sortable: true,
    isSorted: false,
    width: '0.75fr'
  }
]

export enum SetsListsActions {
  Delete = 'delete',
  Generate = 'generate',
  Preview = 'preview'
}

export const itemComponentConfig: Record<SetType, VueConstructor> = {
  [PossibleRelatedType.Articles]: ArticlesSetItem,
  [PossibleRelatedType.Products]: ProductsSetItem
}

export const rowComponentConfig: Record<SetType, VueConstructor> = {
  [PossibleRelatedType.Articles]: SetTableRow,
  [PossibleRelatedType.Products]: SetTableRow
}

export const sourceComponentConfig: Record<SetType, VueConstructor | null> = {
  [PossibleRelatedType.Articles]: SourcesForm,
  [PossibleRelatedType.Products]: null
}

export const itemsModelConfig: Record<SetType, ModelsConfig> = {
  [PossibleRelatedType.Articles]: {
    useAdapter: true,
    model: ArticlesItem,
    adapterMap: articlesItemAdapterMap
  },
  [PossibleRelatedType.Products]: {
    useAdapter: true,
    model: ProductsItem,
    adapterMap: productsItemAdapterMap
  }
}

export const hasSourceSiteConfig: Record<SetType, boolean> = {
  [PossibleRelatedType.Articles]: false,
  [PossibleRelatedType.Products]: true
}

export const setsRowActionsFactory = (
  domain: string,
  user: UserModel | null,
  setType: SetType
): TableSearchBarAction[] => {
  const actions = []

  switch (setType) {
    case PossibleRelatedType.Articles:
      if (canEditArticleSet(domain, user)) {
        actions.push(
          {
            label: 'Przeglądaj',
            icon: DashmixIconName.EyeSolid,
            isBulkAction: false,
            action: SetsListsActions.Preview
          }
        )
      }

      if (canDeleteArticleSet(domain, user)) {
        actions.push(
          {
            label: 'Usuń',
            icon: DashmixIconName.TrashAltSolid,
            isBulkAction: true,
            action: SetsListsActions.Delete,
            theme: DashmixTheme.Danger
          }
        )
      }
      break
    case PossibleRelatedType.Products:
      if (canEditProductSet(domain, user)) {
        actions.push(
          {
            label: 'Przeglądaj',
            icon: DashmixIconName.EyeSolid,
            isBulkAction: false,
            action: SetsListsActions.Preview
          }
        )
      }

      if (canDeleteProductSet(domain, user)) {
        actions.push(
          {
            label: 'Usuń',
            icon: DashmixIconName.TrashAltSolid,
            isBulkAction: true,
            action: SetsListsActions.Delete,
            theme: DashmixTheme.Danger
          }
        )
      }
  }

  return actions
}
