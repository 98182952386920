























import { Component, Vue } from 'vue-property-decorator'
import { DashmixIconName } from '@movecloser/ui-core'
import { Authentication, AuthServiceType, EventbusType, IEventbus, IModal, ModalType } from '@movecloser/front-core'

import { Inject } from '../../../backoffice'

import { UserModel } from '../../auth/contracts/models'

import { ISiteResolver, SiteResolverType } from '../services/site-resolver'
import { SiteModel } from '../contracts/models'
import { SiteSelectItem } from './SiteSelectItem.vue'

/**
 * @author Michał Rossian <michal.rossian@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
@Component({
  name: 'SiteSelectionModal',
  components: { SiteSelectItem }
})
export class SiteSelectionModal extends Vue {
  @Inject(AuthServiceType)
  private authService!: Authentication<UserModel>

  @Inject(EventbusType)
  private eventBus!: IEventbus

  @Inject(SiteResolverType)
  private siteResolver!: ISiteResolver

  @Inject(ModalType)
  protected modalConnector!: IModal

  public icons = DashmixIconName
  public selected: string = ''

  public get sites (): SiteModel[] {
    const sitesWithAccess: string[] = this.authService.user?.sitesWithAccess() || []
    return this.siteResolver.getDictionary().filter(s => {
      return sitesWithAccess.includes(s.domain)
    })
  }

  mounted () {
    this.selected = this.siteResolver.getSite()?.domain || ''
  }

  public close (): void {
    this.$emit('close')
  }

  public select (): void {
    const site = this.siteResolver.findByName(this.selected)
    if (site) {
      this.siteResolver.setSite(site)
      this.eventBus.emit('ui:context.changed')
      this.close()
    }
  }
}

export default SiteSelectionModal
