/**
 * @author Javlon Khalimjanov <javlon.khalimjanov2000@gmail.com>
 */
export enum ProductsSetCategoryPriceGroup {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  G = 'G'
}
