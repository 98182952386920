

























































// Copyright © 2022 Move Closer

import { DashmixIconName, DashmixSelectItem } from '@movecloser/ui-core'
import debounce from 'lodash/debounce'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { ICollection, IModal, ModalType } from '@movecloser/front-core'

import { Inject, IRelatedService, logger, RelatedServiceType, Identifier, SetType } from '../../../../backoffice'

import { composeSelectedArray } from '../../../shared/helpers/pickers'

import { ISiteResolver, SiteResolverType } from '../../../root/services/site-resolver'
import { Loader } from '../../../shared/components/Loader'
import { PickerPayload } from '../../../content/contracts'

import SetPickerItem from '../SetPickerItem.vue'
import SetSidebar from '../SetSidebar.vue'

import { ISetsRepository, SetModel, SetsRepositoryType } from '../../contracts'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
@Component<AbstractSetPickerModal>({
  name: 'AbstractSetPickerModal',
  components: { Loader, SetPickerItem, SetSidebar },

  created () {
    this.init()
  }
})
export class AbstractSetPickerModal extends Vue {
  @Prop({ type: Object, required: true })
  public payload!: PickerPayload<SetModel>

  @Inject(ModalType)
  protected modalConnector!: IModal

  @Inject(RelatedServiceType)
  protected relatedService!: IRelatedService

  @Inject(SetsRepositoryType)
  private setsRepository!: ISetsRepository

  @Inject(SiteResolverType)
  protected siteResolver!: ISiteResolver

  public site: Identifier | undefined
  public siteOptions: DashmixSelectItem[] = []
  public setType!: SetType

  protected query: string = ''
  protected setList: SetModel[] = []
  protected selected: SetModel[] = []
  protected activeSet: SetModel | null = null

  private icons = DashmixIconName
  private isLoading: boolean = true
  private isSetLoading: boolean = false

  public get isReady (): boolean {
    return !!this.selected && !!this.selected.length
  }

  public get isMultiple (): boolean {
    return this.payload.multiple ?? false
  }

  public get siteId (): number | undefined {
    return this.payload.config?.siteId
  }

  public apply (): void {
    if (!this.setType) {
      throw new Error('[AbstractSetPickerModal]: Piker type not define!')
    }
    if (this.payload.onSelection && typeof this.payload.onSelection === 'function') {
      const result = this.selected.map(item => {
        return { type: this.setType, value: item.id } as unknown as any
      })

      this.payload.onSelection(this.isMultiple ? result : result[0])
    }

    this.modalConnector.close()
  }

  public close (): void {
    if (this.payload.onClose && typeof this.payload.onClose === 'function') {
      this.payload.onClose()
    }
    this.modalConnector.close()
  }

  public isSelected (id: Identifier): boolean {
    return !!this.selected && this.selected.filter(item => item.id === id).length > 0
  }

  public loadSet (id: Identifier, isSelected: boolean): Promise<void> {
    this.isSetLoading = true

    return new Promise((resolve, reject) => {
      this.setsRepository.load(id, this.site)
        .then((set: SetModel) => {
          if (isSelected) {
            this.updateSelected(set)
          }
          this.activeSet = set

          resolve()
        })
        .catch(error => {
          logger(error, 'error')

          reject(error)
        })
        .finally(() => {
          this.isSetLoading = false
        })
    })
  }

  public loadSetList () {
    this.isLoading = true

    if (!this.setType) {
      throw new Error('[AbstractSetPickerModal] Piker type not define!')
    }

    this.setsRepository.loadCollection(this.setType, { q: this.query }, this.site)
      .then((setList: ICollection<SetModel>) => {
        this.setList = setList
      })
      .catch(error => {
        logger(error, 'error')
      })
      .finally(() => {
        this.isLoading = false
      })
  }

  public changeSets () {
    this.activeSet = null
    this.selected = []

    this.$nextTick(() => {
      this.loadSetList()
    })
  }

  public toggleSet (set: SetModel) {
    if (this.isSelected(set.id)) {
      this.selected = this.selected.filter(item => item.id !== set.id)
    } else {
      this.selected.push(set)
    }
  }

  public search () {
    this.searchDebounce()
  }

  public searchDebounce = debounce(this.loadSetList.bind(this), 500)

  public updateSelected (set: SetModel) {
    if (this.isMultiple) {
      this.selected.push(set)
    } else {
      this.selected = [set]
    }
  }

  protected init (): void {
    const promises: Promise<void>[] = []

    this.site = this.siteResolver.getSite()?.id
    this.siteOptions = [...this.siteResolver.getDictionary()]
      .map(site => {
        return {
          label: `${site.domain} ${site.locale}`,
          value: site.id
        }
      })

    if (this.payload.selected) {
      for (const id of composeSelectedArray(this.payload.selected, !!this.payload.multiple)) {
        promises.push(
          this.loadSet(id, true)
        )
      }
    }

    Promise.all(promises).then(() => {
      this.loadSetList()
    })
  }
}

export default AbstractSetPickerModal
