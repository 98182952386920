// Copyright © 2021 Move Closer

import { AnyModule } from '../../../../backoffice'
import { AnyObject, Injectable } from '@movecloser/front-core'
import { BehaviorSubject, Subscription } from 'rxjs'

import {
  IPageBuilderService,
  ModulesCopyPayload,
  PageBuilderEvent,
  PageBuilderEventType
} from './PageBuilder.contracts'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
@Injectable()
export class PageBuilderService implements IPageBuilderService {
  protected subject: BehaviorSubject<PageBuilderEvent>

  constructor (
    protected readonly storageKey: string = 'selena_builder_clipboard',
    protected readonly validTtl: number = (60 * 15 * 1000)
  ) {
    this.subject = new BehaviorSubject<PageBuilderEvent>({ type: PageBuilderEventType.Init })
    this.boot()
  }

  public copyToStorage (copyPasteObject: AnyObject): void {
    const copyPayload: ModulesCopyPayload = {
      appVersion: process.env.VUE_APP_VERSION,
      copiedAt: Number(new Date()),
      copyPasteObject
    }

    localStorage.setItem(this.storageKey, JSON.stringify(copyPayload))

    if (this.hasCopiedModules()) {
      this.subject.next({ type: PageBuilderEventType.Copied })
      this.postPoneInvalidation()
    }
  }

  public hasCopiedModules (): boolean {
    const inStorage: string | null = localStorage.getItem(this.storageKey)

    if (!inStorage) {
      return false
    }

    const parsed: AnyObject = JSON.parse(inStorage)
    return parsed !== null &&
      ['appVersion', 'copiedAt', 'copyPasteObject'].filter(key => !Object.keys(parsed).includes(key)).length === 0
  }

  public pasteFromStorage (): AnyObject {
    if (!this.hasCopiedModules()) {
      return []
    }

    const inStorage: ModulesCopyPayload = this.retrievePayload()
    return inStorage.copyPasteObject
  }

  public listen (callback: (event: PageBuilderEvent) => void): Subscription {
    return this.subject.subscribe((event: PageBuilderEvent) => {
      callback(event)
    })
  }

  protected boot (): void {
    if (this.hasCopiedModules()) {
      this.invalidateIfNeeded()
    }

    window.addEventListener('storage', (event: StorageEvent) => {
      if (event.key === this.storageKey && this.hasCopiedModules()) {
        this.subject.next({ type: PageBuilderEventType.Copied })
      }
    })
  }

  protected retrievePayload (): ModulesCopyPayload {
    if (!this.hasCopiedModules()) {
      throw new Error('[PageBuilderService]: Cannot retrieve payload when there\'s noting copied.')
    }

    return JSON.parse(
      localStorage.getItem(this.storageKey) || ''
    )
  }

  private invalidateIfNeeded (): void {
    const now = Number(new Date())
    const inStorage: ModulesCopyPayload = this.retrievePayload()

    if (now >= inStorage.copiedAt + this.validTtl) {
      localStorage.removeItem(this.storageKey)
      this.subject.next({ type: PageBuilderEventType.Cleared })
    } else {
      this.postPoneInvalidation()
    }
  }

  private postPoneInvalidation (): void {
    setTimeout(() => {
      this.invalidateIfNeeded()
    }, (60 * 5 * 1000))
  }
}
