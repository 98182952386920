



























































import { Component, Prop, Vue } from 'vue-property-decorator'
import { DateTimeType, IDateTime, IModal, ModalType } from '@movecloser/front-core'
import { DashmixIconName, DashmixTheme } from '@movecloser/ui-core'
import { HandleDirective } from 'vue-slicksort'

import { Identifier, Inject, SetType } from '../../../backoffice'
import { Modals } from '../..//shared/config/modals'

import { ISetItemsRepository, SetItemsRepositoryType } from '../contracts'

@Component({
  name: 'SetDraggableItem',
  directives: { handle: HandleDirective }
})
export class SetDraggableItem extends Vue {
  @Prop({ type: String, required: false })
  public category!: string

  @Prop({ type: Number, required: true })
  public childrenCount!: number

  @Prop({ type: Number, required: true })
  public id!: Identifier

  @Prop({ type: Boolean, required: false, default: true })
  public isVisible!: boolean

  @Prop({ type: Boolean, required: true })
  public locked!: boolean

  @Prop({ type: String, required: true })
  public name!: string

  @Prop({ type: Number, required: true })
  public position!: number

  @Prop({ type: Number, required: true })
  public setId!: Identifier

  @Prop({ type: String, required: true })
  public setType!: SetType

  @Inject(DateTimeType)
  protected readonly dateTime!: IDateTime

  @Inject(ModalType)
  protected readonly modalConnector!: IModal

  @Inject(SetItemsRepositoryType)
  private readonly setItemsRepository!: ISetItemsRepository

  public DashmixTheme = DashmixTheme
  public Icons = DashmixIconName

  public showPositionInput: boolean = false
  public updatedPosition: number = this.position

  public moveTo (): void {
    if (this.updatedPosition <= 0) return

    this.$emit('move-to', { newIndex: this.updatedPosition - 1, oldIndex: this.position - 1 })
    this.showPositionInput = false
  }

  public onDelete (): void {
    this.modalConnector.open(Modals.Confirm, {
      content: {
        buttonLabel: this.$t('atoms.delete'),
        contentText: this.$t(`setsList.delete.contentText.${this.setType}`) + `${this.name}`,
        header: this.$t(`setsList.delete.header.${this.setType}`),
        theme: 'danger'
      },
      onConfirm: () => {
        this.modalConnector.close()
        this.$emit('remove', this.id)
      },
      onClose: () => this.modalConnector.close()
    })
  }

  public onLock (): void {
    this.$emit('lock', this.id)
  }

  public onUnlock (): void {
    this.$emit('unlock', this.id)
  }
}

export default SetDraggableItem
