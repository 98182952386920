









import { DashmixIconName, DashmixSelectItem, DashmixTheme } from '@movecloser/ui-core'
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'
import { Inject, logger } from '../../../support'
import { ISiteResolver, SiteResolverType } from '../../root/services/site-resolver'

@Component<SourceSiteForm>({
  name: 'SourceSiteForm',
  components: { },
  mounted () {
    this.getSitesDictionary()
  }
})
export class SourceSiteForm extends Vue {
  @Inject(SiteResolverType)
  private siteResolver!: ISiteResolver

  @PropSync('model', { type: Number, required: false, default: null })
  public siteId!: number | null

  @Prop({ type: Boolean, required: false, default: true })
  public editable!: boolean

  @Prop({ type: Boolean, required: false, default: false })
  public isLoading!: boolean

  public buttonTheme = DashmixTheme
  public Icons = DashmixIconName

  public sitesOptions: DashmixSelectItem[] | [] = []
  public sitesLoading: boolean = false

  protected async getSitesDictionary (): Promise<void> {
    this.sitesLoading = true

    try {
      const dict = await this.siteResolver.getDictionary()

      this.sitesOptions = [...dict].map(site => {
        return {
          value: site.id,
          label: `${site.domain} ${site.locale}`
        }
      })

      this.sitesOptions.unshift({
        value: null,
        label: this.$t('content.addons.dynamicPage.siteNotSelected').toString()
      })
    } catch (e) {
      logger(e, 'warn')
    }

    this.sitesLoading = false
  }
}

export default SourceSiteForm
