// Copyright © 2021 Move Closer

import clone from 'lodash/clone'
import { ComponentObjectPropsOptions } from '@movecloser/ui-core'
import { computed, onMounted, PropType, ref, SetupContext } from '@vue/composition-api'
import { AnyObject } from '@movecloser/front-core'

import {
  AnyModule,
  IRelatedService,
  logger,
  ModuleConstructor,
  ModuleVersion,
  resolve
} from '../../../../../backoffice'

import {
  IVariantsRepository,
  ModuleValidationResult,
  VariantModel,
  VariantsRepositoryType
} from '../../../../content/contracts'

import {
  FormDrawerProps,
  ModuleComponentsRegistry,
  ModuleSourceComponentsRegistry,
  ModulesRenderRegistry,
  ModulesVersionsRegistry,
  UseFormDrawerProvides
} from '../PageBuilder.contracts'
import { useModuleDependencies } from './shared.hooks'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const formDrawerProps: ComponentObjectPropsOptions<FormDrawerProps> = {
  relatedService: {
    type: Object as PropType<IRelatedService>,
    required: true
  },
  source: {
    type: Object, // as PropType<AnyModule>,
    required: true
  },
  variant: {
    type: Object as PropType<VariantModel>,
    required: false
  }
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export function useFormDrawer (props: FormDrawerProps, ctx: SetupContext): UseFormDrawerProvides {
  const module = ref<AnyModule | null>(null)
  const selectedForm = ref<ModuleConstructor | string | null>(null)
  const selectedName = ref<string>('')
  const siteId = ref<number | undefined>()

  siteId.value = props.variant && props.variant.addons && props.variant.addons.includes('product-config') && props.variant.properties
    ? props.variant.properties['product-config']?.siteId
    : undefined

  const { pickRelated } = useModuleDependencies()

  const variantRepository = resolve<IVariantsRepository>(VariantsRepositoryType)

  const _forms = computed<ModulesRenderRegistry>(() => {
    return ctx.root.$options.configuration?.byKey('builder.formComponents') || {}
  })

  const sourceComponentsRegistry = computed<ModuleSourceComponentsRegistry>(() => {
    return ctx.root.$options.configuration?.byKey('builder.sourceComponents', false) || {}
  })

  const versionsRegistry = computed<ModulesVersionsRegistry>(() => {
    return ctx.root.$options.configuration?.byKey('builder.versionsRegistry') || {}
  })

  const errors = ref<string[]>([])
  const validating = ref<boolean>(false)

  const content = computed<AnyObject>({
    get: () => {
      if (!module.value) {
        return {}
      }

      return module.value.content
    },
    set: (content: AnyObject) => {
      if (!module.value) {
        return
      }
      const edited = module.value
      edited.content = content

      module.value = edited
    }
  })

  const version = computed<ModuleVersion | undefined>({
    get: () => {
      if (!module.value) {
        return undefined
      }

      return module.value.version
    },
    set: (version: string | undefined) => {
      if (!module.value) {
        return
      }
      const edited = module.value
      edited.version = version

      module.value = edited
    }
  })

  const isOpen = computed<boolean>(() => {
    return module.value !== null && selectedForm.value !== null
  })

  /**
   * Set cloned module data to prevent unintentional changes.
   */
  function _cloneModule (source: AnyModule): void {
    if (!source) {
      logger('FormDrawer: Incorrect type of [module]')
    }
    module.value = clone(source)
  }

  /**
   * Resolve form component for given module.
   */
  function _selectFormComponent (source: AnyModule): void {
    const found: ModuleConstructor | undefined = _forms.value[source.driver]
    if (!found) {
      selectedForm.value = 'div'
      selectedName.value = 'unknown'
      return
    }

    const registry = ctx.root.$options.configuration?.byKey<ModuleComponentsRegistry>('builder.moduleRegistry') || {}

    selectedForm.value = found
    selectedName.value = registry[source.driver]?.label ?? 'unknown'
  }

  function apply (): void {
    ctx.emit('apply', module.value)
    ctx.emit('close')
    module.value = null
  }

  /**
   * Apply all changes that were placed.
   */
  function applyChanges (): void {
    if (!module.value) {
      logger('[FormDrawer] Module.value is `null`', 'error')
      return
    }

    validating.value = true

    variantRepository.validate(module.value, 'module').then((result: ModuleValidationResult) => {
      if (result.isValid) {
        apply()
      }

      errors.value = result.errors
    }).catch((error: Error) => {
      logger(error, 'warn')
    }).finally(() => {
      validating.value = false
    })
  }

  onMounted(() => {
    _cloneModule(props.source)
    _selectFormComponent(props.source)
  })

  return {
    applyChanges,
    content,
    errors,
    isOpen,
    module,
    pickRelated,
    selectedForm,
    selectedName,
    siteId,
    sourceComponentsRegistry: sourceComponentsRegistry.value,
    version,
    validating,
    versionsRegistry: versionsRegistry.value
  }
}
