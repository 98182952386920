// Copyright © 2021 Move Closer

import { Headers, ICollection } from '@movecloser/front-core'
import { SiteModel } from '../../contracts/models'

export interface ISiteResolver {
  boot(): void
  findByDomain (domain: string): SiteModel|null
  findByName (name: string): SiteModel|null
  findBySiteId (siteId: string): SiteModel|null
  getDictionary (): ICollection<SiteModel>
  getSite (): SiteModel|null
  getSiteId<T extends true|false> (force?: T): T extends true ? string : string | undefined
  getSiteAddress(): string
  getSiteHeader (): Headers
  hasSiteSet (): boolean
  setSite (site: SiteModel): void
  setSiteFromParam (param: string): SiteModel
  setupDictionary (collection: ICollection<SiteModel>): void
}

export interface ISiteService {
  boot(): Promise<void>
}

export const SiteResolverType = Symbol.for('ISiteResolver')
export const SiteServiceType = Symbol.for('ISiteService')
