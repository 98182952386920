














import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'
import { ContainersRegistry, ContentStructure, ModulesRegistry } from '@movecloser/page-builder'

import { PageBuilder } from '../../shared/components/PageBuilder'
import { IRelatedService } from '../../../contexts'

import { LayoutSlot } from '../contracts'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Component({
  name: 'LayoutSlotForm',
  components: { PageBuilder }
})
export class LayoutSlotForm extends Vue {
  @PropSync('containers', { type: Object, required: true })
  public _containers!: ContainersRegistry

  @PropSync('modules', { type: Object, required: true })
  public _modules!: ModulesRegistry

  @Prop({ type: Number, required: false })
  public readonly columns?: number

  @Prop({ type: String, required: true })
  public readonly layoutSlot!: LayoutSlot

  @Prop({ type: Object, required: true })
  public readonly relatedService!: IRelatedService

  @Prop({ type: Object, required: true })
  public readonly structure!: ContentStructure

  public contentType: string = 'layout'

  public get title (): string {
    return this.$t(`layouts.slotTitles.${this.layoutSlot}`).toString()
  }

  public onStructureChange (changed: ContentStructure): void {
    this.$emit('update', { [this.layoutSlot]: changed })
  }
}

export default LayoutSlotForm
