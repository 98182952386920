// Copyright © 2022 Move Closer

import {
  ConnectorMiddleware,
  CSRFServiceType, DateTime, DateTimeType,
  HttpConnectorType, IBaseDateTime,
  IConfiguration,
  ICSRFService,
  Interfaces,
  ProvidersFactory
} from '@movecloser/front-core'

import { IPageBuilderService, PageBuilderService, PageBuilderServiceType } from '@component/PageBuilder'
import {
  IRelatedService,
  LazyLoadedRelatedService,
  RelatedLoader,
  RelatedLoaderType,
  RelatedServiceType,
  resolveDrivers
} from '@core'

import { CSRFService } from '@service/csrf'
import {
  IToastOptions,
  IToastService,
  ToastMiddleware,
  ToastMiddlewareType,
  ToastService,
  ToastServiceType
} from '@service/toaster'
import { RelatedRepository } from '@service/related.repository'

/**
 * Global services binding.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const services: ProvidersFactory = (config: IConfiguration) => {
  return (bind: Interfaces.Bind) => {
    bind<ConnectorMiddleware>(ToastMiddlewareType).toDynamicValue((context: Interfaces.Context) => {
      return new ToastMiddleware(
        context.container.get<IToastService<string, IToastOptions>>(ToastServiceType)
      )
    })

    bind<ICSRFService>(CSRFServiceType).toDynamicValue((context: Interfaces.Context) => {
      return new CSRFService(
        config.byFile('csrf'),
        context.container.get(HttpConnectorType)
      )
    }).inSingletonScope()

    bind<IPageBuilderService>(PageBuilderServiceType).to(PageBuilderService)

    bind<IRelatedService>(RelatedServiceType).toDynamicValue((context: Interfaces.Context) => {
      return new LazyLoadedRelatedService(
        resolveDrivers(),
        context.container.get(RelatedLoaderType)
      )
    }).inRequestScope()

    bind<IToastService<string, IToastOptions>>(ToastServiceType).toDynamicValue(() => {
      return new ToastService(
        config.byFile('toasts')
      )
    })

    bind<RelatedLoader>(RelatedLoaderType).to(RelatedRepository).inSingletonScope()
    bind<IBaseDateTime>(DateTimeType).to(DateTime).inSingletonScope()
  }
}
